import * as React from "react";
import Container from "../Container/Container";
import "./BookExclusive.scss";

// markup
export default function BookArchive(props) {
  return (
    <section
      id="book-in-progress"
      className="book-cta book-in-progress u-pad-top-xl u-pad-btm-xl"
    >
      <h2 className="book-cta__heading book-in-progress__heading">
        This book is{" "}
        <span className="book-in-progress__heading-highlight">Exclusive to Wattpad</span>
      </h2>
      <Container name="book-in-progress">
        <p>
          <strong>This book is finished</strong> and you can read the entire thing for <strong>FREE</strong>, exclusively on Wattpad. Read it now and follow me for more updates!
        </p>
        <div className="book-in-progress__buttons">
          { 
            // <a
            // href="https://patreon.com/yvetterussell"
            // target="_new"
            // className="book-cta__button book-in-progress__button"
            // >
            // Read on Patreon
            // </a>
          }
          <a
            href={props.wattpad}
            target="_new"
            className="book-cta__button book-in-progress__button"
          >
            Read on Wattpad
          </a>
        </div>
      </Container>
    </section>
  );
}
