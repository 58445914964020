import * as React from "react";
import "./BookBuy.scss";

// markup
export default function BookBuy(props) {
  return (
    <section
      id="book-buy"
      className="book-cta book-buy u-pad-top-xl u-pad-btm-xl"
    >
      <h2 className="book-cta__heading book-buy__heading u-mar-btm-xs">
        <span className="book-buy__heading-highlight">Read it Now</span> for{" "}
        <span className="book-buy__heading-highlight">
          {props.price ? props.price : "Free"}
        </span>
      </h2>
      <ul className="book-buy__list">
        {props.kobo && (
          <li className="book-buy__list-item">
            <a href={props.kobo} className="book-cta__button book-buy__button">
              Kobo
            </a>
          </li>
        )}
        {props.apple && (
          <li className="book-buy__list-item">
            <a href={props.apple} className="book-cta__button book-buy__button">
              Apple
            </a>
          </li>
        )}
        {props.nook && (
          <li className="book-buy__list-item">
            <a href={props.nook} className="book-cta__button book-buy__button">
              Nook
            </a>
          </li>
        )}
        {props.wattpad && (
          <li className="book-buy__list-item">
            <a
              href={props.wattpad}
              className="book-cta__button book-buy__button"
            >
              Wattpad
            </a>
          </li>
        )}
        {props.smashwords && (
          <li className="book-buy__list-item">
            <a
              href={props.smashwords}
              className="book-cta__button book-buy__button"
            >
              Smashwords
            </a>
          </li>
        )}
        {props.amazon && (
          <li className="book-buy__list-item">
            {" "}
            <a
              href={props.amazon}
              className="book-cta__button book-buy__button"
            >
              Amazon
            </a>
          </li>
        )}
      </ul>
    </section>
  );
}
