import React from "react";
import Container from "../Container/Container";
import "./BookTitle.scss";

export default function BookTitle(props) {
  return (
    <header className="book-title">
      <Container name="book-title">
        {props.endorsement && (
          <span className="book-title__subtitle u-mar-btm-xs">
            {props.endorsement}
          </span>
        )}
        <h1 className="book-title__title">{props.title}</h1>
        {props.series && (
          <span className="book-title__subtitle u-mar-top-xs">
            {props.series}
          </span>
        )}
      </Container>
    </header>
  );
}
