import * as React from "react";
import { Link } from "gatsby";
import SocialLinks from "../Social/SocialLinks";
import Logo from "../Logo/Logo";
import "./BookFooter.scss";

// markup
export default function Footer(props) {
  return (
    <footer className={"book-footer " + props.className}>
      <p className="book-footer__copyright">
        Copyright &copy; {new Date().getFullYear()} Yvette Russell
      </p>
      <nav className="book-footer__nav">
        <ul className="book-footer__nav-list">
          <li className="book-footer__nav-item">
            <Link to="/about" className="book-footer__nav-link">
              About
            </Link>
          </li>
          <li className="book-footer__nav-item">
            <Link to="/books" className="book-footer__nav-link">
              Books
            </Link>
          </li>
          <li className="book-footer__nav-item">
            <Link to="/contact" className="book-footer__nav-link">
              Contact
            </Link>
          </li>
        </ul>
        <SocialLinks />
        <Link to="/" className="book-footer__home-link">
          <Logo className="book-footer__logo" />
        </Link>
      </nav>
    </footer>
  );
}
