import React from "react";
import { graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet";
import BookHeader from "../components/Book/BookHeader";
import BookTitle from "../components/Book/BookTitle";
import Container from "../components/Container/Container";
import BookCover from "../components/Book/BookCover";
import BookBlurb from "../components/Book/BookBlurb";
import BookBuy from "../components/Book/BookBuy";
import BookArchive from "../components/Book/BookArchive";
import BookExclusive from "../components/Book/BookExclusive";
import BookInProgress from "../components/Book/BookInProgress";
import OtherBooks from "../components/OtherBooks/OtherBooks";
import BookFooter from "../components/Book/BookFooter";
import "./BookTemplate.scss";

export default function Template({ data }) {
  const { markdownRemark } = data;
  const { frontmatter, html } = markdownRemark;
  const image = getImage(frontmatter.bookCover);
  return (
    <main className={`book book--${frontmatter.class}`}>
      <Helmet defer={false}>
        <meta charSet="utf-8" />
        <title>{frontmatter.title} | a book by Yvette Russell</title>
        <meta
          name="description"
          content={frontmatter.tagline}
        />
      </Helmet>
      <BookHeader />
      <BookTitle
        title={frontmatter.title}
        endorsement={frontmatter.endorsement}
        series={
          frontmatter.series
            ? "Book " + frontmatter.series + " of " + frontmatter.tags
            : ""
        }
      />
      <div className="book__details">
        <Container name="book-description">
          <BookCover
            price={frontmatter.price}
            image={image}
            alt={frontmatter.title}
          />
          <BookBlurb tagline={frontmatter.tagline}>
            <div
              className="book-blurb__children"
              dangerouslySetInnerHTML={{ __html: html }}
            />
          </BookBlurb>
        </Container>
      </div>
      {frontmatter.template === "default" ? (
        <BookBuy
          price={frontmatter.price ? frontmatter.price : ""}
          amazon={frontmatter.linkAmazon ? frontmatter.linkAmazon : ""}
          apple={frontmatter.linkApple ? frontmatter.linkApple : ""}
          kobo={frontmatter.linkKobo ? frontmatter.linkKobo : ""}
          nook={frontmatter.linkNook ? frontmatter.linkNook : ""}
          smashwords={
            frontmatter.linkSmashwords ? frontmatter.linkSmashwords : ""
          }
          wattpad={frontmatter.linkWattpad ? frontmatter.linkWattpad : ""}
        />
      ) : frontmatter.template === "archived" ? (
        <BookArchive />
      ) : frontmatter.template === "exclusive" ? (
        <BookExclusive wattpad={frontmatter.linkWattpad ? frontmatter.linkWattpad : ""} />
      ) : (
        <BookInProgress wattpad={frontmatter.linkWattpad ? frontmatter.linkWattpad : ""} />
      )}
      <OtherBooks type="stinger" className="u-mar-top-md u-mar-btm-lg" />
      <BookFooter className="u-mar-top-md" type="book-landing" />
    </main>
  );
}

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      html
      frontmatter {
        date(formatString: "YYYY")
        slug
        tags
        template
        class
        title
        series
        tagline
        endorsement
        price
        linkAmazon
        linkApple
        linkKobo
        linkNook
        linkSmashwords
        linkWattpad
        bookCover {
          childImageSharp {
            gatsbyImageData(
              width: 400
              placeholder: BLURRED
              formats: [AUTO, WEBP, AVIF]
              quality: 100
            )
          }
        }
      }
    }
  }
`;
