import React from "react";
import "./BookBlurb.scss";

export default function BookBlurb(props) {
  return (
    <div className="book-blurb">
      {props.tagline && (
        <h3 className="book-blurb__tagline u-mar-btm-xs">{props.tagline}</h3>
      )}
      <div className="book-blurb__description">{props.children}</div>
    </div>
  );
}
