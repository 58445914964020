import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import { Link } from "gatsby";
import "./BookThumb.scss";

export default function BookThumb(props) {
  const image = getImage(props.image);
  return (
    <Link
      to={props.link}
      target={props.target}
      className={
        "book-thumb " + (props.type ? "book-thumb--" + props.type : "")
      }
    >
      <div className="book-thumb__cover">
        <GatsbyImage
          className="book-thumb__cover-image"
          image={image}
          alt={props.title}
        />
      </div>
      {props.wip ? (
        <span className="book-thumb__current">Work in Progress</span>
      ) : props.series ? (
        <span className="book-thumb__series">Book {props.series}</span>
      ) : (
        ""
      )}
      <span className="book-thumb__title">{props.title}</span>
    </Link>
  );
}
