import * as React from "react";
import Container from "../Container/Container";
import "./BookArchive.scss";

// markup
export default function BookArchive(props) {
  return (
    <section
      id="book-archive"
      className="book-cta book-archive u-pad-top-xl u-pad-btm-xl"
    >
      <h2 className="book-cta__heading book-archive__heading u-mar-btm-xs">
        This book has been{" "}
        <span className="book-archive__heading-highlight">Archived</span>
      </h2>
      <Container name="book-archive">
        <p>
          <strong>This book has been archived from the public.</strong> You can still get a copy
          from my Ko-Fi for a small fee.
        </p>
        <a
          href="https://ko-fi.com/s/969f4caddc"
          target="_new"
          className="book-cta__button book-archive__button"
        >
          Download from Ko-Fi
        </a>
      </Container>
    </section>
  );
}
