import React from "react";
import BookPrice from "./BookPrice";
import { GatsbyImage } from "gatsby-plugin-image";
import "./BookCover.scss";

export default function BookCover(props) {
  return (
    <div className="book-cover__container">
      {props.price && <BookPrice price={props.price} />}
      <GatsbyImage className="book-cover" image={props.image} alt={props.alt} />
    </div>
  );
}
