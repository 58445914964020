import * as React from "react";
import { Link } from "gatsby";
import "./BookHeader.scss";

// markup
export default function BookHeader() {
  return (
    <div className="book-header__container">
      <Link to="/" className="book-header">
        <span className="book-header__byline">A Book By</span>
        <span className="book-header__author">Yvette Russell</span>
      </Link>
    </div>
  );
}
